import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {RosterWrapper} from '../pages/roster/RosterWrapper'
import {SchedulesWrapper} from '../pages/schedules/SchedulesWrapper'
import {FlightDetailWraper} from '../pages/flight-detail/FlightDetailWrapper'
import {LogbookWrapper} from '../pages/logbook/LogbookWrapper'
import BidLineWrapper from '../pages/bid-line/BidLineWrapper'
import {BidsWrapper} from '../pages/bids/BidsWrapper'
import {LiveMapWrapper} from '../pages/live-map/LiveMapWrapper'
import {RouteChangelogWrapper} from '../pages/route-changelog/RouteChangelogWrapper'
import {EventCalendarWrapper} from '../pages/event-callendar/EventCalendarWrapper'
import {ActiveBidLineWrapper} from '../pages/active-bid-line/ActiveBidLineWrapper'
import {RouteMapWrapper} from '../pages/route-map/RouteMapWrapper'
const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const AirportPage = lazy(() => import('../pages/airports/AirportPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='login/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='roster' element={<RosterWrapper />} />
        <Route path='schedules' element={<SchedulesWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='airport/*'
          element={
            <SuspensedView>
              <AirportPage />
            </SuspensedView>
          }
        />
        <Route
          path='route/*'
          element={
            <SuspensedView>
              <FlightDetailWraper />
            </SuspensedView>
          }
        />

        <Route
          path='flights'
          element={
            <SuspensedView>
              <LogbookWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='route-map'
          element={
            <SuspensedView>
              <RouteMapWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='pbs'
          element={
            <SuspensedView>
              <BidLineWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='bid-lines'
          element={
            <SuspensedView>
              <ActiveBidLineWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='manage'
          element={
            <SuspensedView>
              <BidsWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='live-map'
          element={
            <SuspensedView>
              <LiveMapWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='route-changelog'
          element={
            <SuspensedView>
              <RouteChangelogWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='events'
          element={
            <SuspensedView>
              <EventCalendarWrapper />
            </SuspensedView>
          }
        />

        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
