import {FC} from 'react'
import {useFormikContext} from 'formik'
import {BidTable} from '../BidTable'

type Props = {
  schedules: any
  loading: boolean
}

const Step3: FC<Props> = ({schedules, loading}) => {
  const {submitForm, setFieldValue} = useFormikContext()
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bold text-dark'>Preview Line</h2>

        <div className='text-gray-400 fw-semibold fs-6'>
          Here is your generated bid line. If you would like to regenerate, please click
          <a
            onClick={() => {
              setFieldValue('_regen', true)
              submitForm()
            }}
            className='link-primary fw-bold cursor-pointer'
          >
            {' '}
            Regenerate Line
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <BidTable data={schedules} loading={loading} />
      </div>

      {/* <div className='fv-row mb-0'></div> */}
    </div>
  )
}

export {Step3}
