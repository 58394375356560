import * as Yup from 'yup'

export interface IbidLineQuery {
  depIcao: string
  legCount: number
  aircraftType: string
  operator: string
  returnToDep: boolean
  _regen?: boolean
}

const bidLineQuerySchemas = [
  Yup.object({
    operator: Yup.string().required().label('Carrier Name'),
  }),
  Yup.object({
    legCount: Yup.number().required().label('Leg Count'),
    depIcao: Yup.string().length(4).required().label('Starting Location'),
    aircraftType: Yup.string().required().label('Equipment Type'),
    returnToDep: Yup.boolean().required().label('Return to Starting Location'),
  }),
]

const inits: IbidLineQuery = {
  depIcao: '',
  legCount: 3,
  aircraftType: '',
  operator: '',
  returnToDep: false,
}

export {bidLineQuerySchemas, inits}
