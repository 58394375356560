import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

type Props = {
  aircraftTypes: string[]
}

const Step2: FC<Props> = ({aircraftTypes}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bold text-dark'>Bid Details</h2>

        {/* <div className='text-gray-400 fw-semibold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bold'>
            {' '}
            Help Page
          </a>
          .
        </div> */}
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3 required'>
          Legs
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='legCount'
              value='3'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bold fs-3'>3</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='legCount'
              value='5'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bold fs-3'>5</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='legCount'
              value='8'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bold fs-3'>8</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='legCount'
              value='10'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bold fs-3'>10</span>
            </label>
          </div>
        </div>

        <div className='form-text'>This will be the number of generated legs for this bid line</div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Starting Location (ICAO)</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='depIcao'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='depIcao' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Equipment</label>

        <Field
          as='select'
          name='aircraftType'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          {aircraftTypes?.map((type: any, index) => {
            if (typeof type === 'string') {
              return (
                <option key={index} value={type}>
                  {type}
                </option>
              )
            }
            return (
              <option key={index} value={type.id}>
                {type.name}
              </option>
            )
          })}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='aircraftType' />
        </div>
      </div>
    </div>
  )
}

export {Step2}
