import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {getAirports, getUsers} from '../../../../middleware'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const {data: airportsData, refetch: refetchAirportsData} = useQuery('airportsData', getAirports, {
    staleTime: Infinity,
    refetchOnMount: false,
  })

  const {
    data: usersData,
    isLoading: dashboardDataIsLoading,
    isError: _,
  } = useQuery('rosterData', getUsers, {
    staleTime: Infinity,
    refetchOnMount: false,
  })

  const searchAirportsData = useMemo(() => {
    if (airportsData?.length) {
      return airportsData.map((item: any) => {
        return {
          ...item,
          type: 'airport',
          text: item.name + item.icao,
          icon: toAbsoluteUrl('/media/svg/icons/Communication/Group.svg'),
        }
      })
    }
    return []
  }, [airportsData])

  const searchUsersData = useMemo(() => {
    if (usersData?.length) {
      return usersData.map((item: any) => {
        return {
          ...item,
          type: 'user',
          text: item.username,
          icon: toAbsoluteUrl('/media/svg/icons/Communication/Group.svg'),
        }
      })
    }
    return []
  }, [usersData])

  const [searchAirportsOut, searchUsersOut] = useMemo(() => {
    if (searchQuery.length) {
      const results = [
        searchAirportsData.filter((item: any) => {
          return item.text.toLowerCase().includes(searchQuery.toLowerCase())
        }),
        searchUsersData.filter((item: any) => {
          return item.text.toLowerCase().includes(searchQuery.toLowerCase())
        }),
      ]
      return results
    }
    return [[], []]
  }, [searchQuery, searchAirportsData, searchUsersData])

  const processs = (search: SearchComponent) => {
    //setTimeout(function () {
    //const number = Math.floor(Math.random() * 6) + 1
    // Hide recently viewed
    try {
      suggestionsElement.current!.classList.add('d-none')
    } catch (error) {
      //console.log(error)
    }
    const query = search.getQuery()

    if (query.length < 2) {
      // Hide results
      resultsElement.current!.classList.add('d-none')
      // Show empty message
      emptyElement.current!.classList.remove('d-none')
    } else {
      // Show results
      resultsElement.current!.classList.remove('d-none')
      // Hide empty message
      emptyElement.current!.classList.add('d-none')
      setSearchQuery(query)
    }
    // set search query to state

    // Complete search
    search.complete()
    // }, 1500)
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
  }, [])

  useEffect(() => {
    // get search data from local storage
    // search_user and search_airport
  }, [])

  const [recentUsersSearch, recentAirportsSearch] = useMemo(() => {
    if (searchAirportsData.length && searchUsersData.length) {
      const recentUsersSearch = JSON.parse(localStorage.getItem('search_user') || '[]')
      const recentAirportsSearch = JSON.parse(localStorage.getItem('search_airport') || '[]')

      // If length is greater than 5, remove the last item
      if (recentUsersSearch.length > 5) {
        recentUsersSearch.pop()
        localStorage.setItem('search_user', JSON.stringify(recentUsersSearch))
      }
      if (recentAirportsSearch.length > 5) {
        recentAirportsSearch.pop()
        localStorage.setItem('search_airport', JSON.stringify(recentAirportsSearch))
      }

      return [
        recentUsersSearch
          .map((item: any) => {
            const match = searchUsersData.find((user: any) => user.public_id === item.id)
            return match
              ? {
                  ...match,
                  date: item.date,
                  type: 'user',
                }
              : false
          })
          .filter(Boolean),
        recentAirportsSearch
          .map((item: any) => {
            const match = searchAirportsData.find((airport: any) => airport.public_id === item.id)
            return match
              ? {
                  ...match,
                  date: item.date,
                  type: 'airport',
                }
              : false
          })
          .filter(Boolean),
      ]
    }
    return [[], []]
  }, [searchAirportsData, searchUsersData])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              {/* <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>

                <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div>
              </div> */}
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {!!searchUsersOut?.length && (
                  <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                    Users
                  </h3>
                )}

                {!!searchUsersOut?.length &&
                  searchUsersOut.map((item: any, index: number) => {
                    return (
                      <a
                        href={`/profile/${item.username}`}
                        onClick={(e) => {
                          e.preventDefault()
                          const searchUsers = JSON.parse(
                            localStorage.getItem('search_user') || '[]'
                          )
                          const newUsers = [
                            ...searchUsers,
                            {id: item.public_id, date: new Date().getTime()},
                          ]
                          localStorage.setItem('search_user', JSON.stringify(newUsers))
                          window.location.href = `/profile/${item.username}`
                        }}
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                        key={item.public_id}
                      >
                        <div className='symbol symbol-40px me-4'>
                          <img
                            src={
                              item.avatar_url ||
                              'https://rotate-group.b-cdn.net/user-images/default.jpg'
                            }
                            alt=''
                          />
                        </div>

                        <div className='d-flex flex-column justify-content-start fw-semibold'>
                          <span className='fs-6 fw-semibold'>{item.username}</span>
                          <span className='fs-7 fw-semibold text-muted'>
                            {item?.ranks?.[0]?.rank}
                          </span>
                        </div>
                      </a>
                    )
                  })}

                {!!searchAirportsOut?.length && (
                  <h3
                    className='fs-5 text-muted m-0 pt-5 pb-5'
                    data-kt-search-element='category-title'
                  >
                    Airports
                  </h3>
                )}

                {!!searchAirportsOut?.length &&
                  searchAirportsOut.map((item: any, index: number) => {
                    return (
                      <a
                        href={`/airport/${item.icao}`}
                        onClick={(e) => {
                          e.preventDefault()
                          const searchAirports = JSON.parse(
                            localStorage.getItem('search_airport') || '[]'
                          )
                          const newAirports = [
                            ...searchAirports,
                            {id: item.public_id, date: new Date().getTime()},
                          ]
                          localStorage.setItem('search_airport', JSON.stringify(newAirports))
                          window.location.href = `/airport/${item.icao}`
                        }}
                        key={item.public_id}
                        className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      >
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol-label bg-light'>
                            <KTSVG
                              path={'/media/icons/duotune/aviation/fill/Airport.svg' || ''}
                              className={'svg-icon-white svg-icon-1 ms-n1'}
                            />
                          </span>
                        </div>

                        <div className='d-flex flex-column justify-content-start fw-semibold'>
                          <span className='fs-6 fw-semibold'>{item.icao}</span>
                          <span className='fs-7 fw-semibold text-muted'>{item.name}</span>
                        </div>
                      </a>
                    )
                  })}

                {/* <h3
                  className='fs-5 text-muted m-0 pt-5 pb-5'
                  data-kt-search-element='category-title'
                >
                  Projects
                </h3>

                <a
                  href='/#'
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen005.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <span className='fs-6 fw-semibold'>Si-Fi Project by AU Themes</span>
                    <span className='fs-7 fw-semibold text-muted'>#45670</span>
                  </div>
                </a> */}
              </div>
            </div>

            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              {[...recentUsersSearch, ...recentAirportsSearch].length > 0 && (
                <div className='d-flex flex-stack fw-semibold mb-4'>
                  <span className='text-muted fs-6 me-2'>Recently Searched:</span>
                </div>
              )}

              <div className='scroll-y mh-200px mh-lg-325px'>
                {[...recentUsersSearch, ...recentAirportsSearch]
                  .sort((a: any, b: any) => {
                    return b.date - a.date
                  })
                  .map((item: any, index: number) => {
                    return (
                      <div className='d-flex align-items-center mb-5' key={'recent-' + item.id}>
                        <div className='symbol symbol-40px me-4'>
                          <span className='symbol-label bg-light'>
                            {item?.avatar_url ? (
                              <img
                                src={
                                  item.avatar_url ||
                                  'https://rotate-group.b-cdn.net/user-images/default.jpg'
                                }
                                alt=''
                                className='h-100 align-self-center'
                              />
                            ) : (
                              <KTSVG
                                path={'/media/icons/duotune/aviation/fill/Airport.svg' || ''}
                                className={'svg-icon-white svg-icon-1 ms-n1'}
                              />
                            )}
                          </span>
                        </div>

                        <div className='d-flex flex-column'>
                          <a
                            href={
                              item?.type === 'user'
                                ? `/profile/${item?.username}`
                                : `/airport/${item?.icao}`
                            }
                            className='fs-6 text-gray-800 text-hover-primary fw-semibold'
                          >
                            {item?.username || item?.icao}
                          </a>
                          <span className='fs-7 text-muted fw-semibold'>
                            {item?.ranks?.[0]?.rank || item?.name}
                          </span>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-semibold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
            <h3 className='fw-semibold text-dark mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='attachment'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bold btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bold btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-semibold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Users
                </span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bold btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bold btn-primary'>Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {Search}
