import clsx from 'clsx'
import {useEffect, useMemo, useRef, useState} from 'react'
import {MapContainer, Marker, Polyline, Popup, TileLayer} from 'react-leaflet'
import {MAPBOX_URI, styleMapLine} from '../../../config'
import {
  getAirports,
  getFlightById,
  getLiveFlightUpdates,
  getPositionReportsByFlightId,
} from '../../../middleware'
import L from 'leaflet'
import {LeafletTrackingMarker} from 'react-leaflet-tracking-marker'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useQuery} from 'react-query'
type Props = {
  className: string
  markers: any
  flightInfo: any
  airportsData: any
}

const icon = L.icon({
  iconUrl: toAbsoluteUrl('/media/misc/map-icon.png'),
  iconSize: [35, 35],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  shadowAnchor: [13, 41],
})

const svgIcon = L.divIcon({
  html: `
            <svg
              width="50"
              height="50"
              viewBox="0 0 100 100"
              version="1.1"
              preserveAspectRatio="none"
              stroke="${styleMapLine}"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle r="5" cx="10" cy="10" fill="orange" />
            </svg>`,
  className: '',
  iconSize: [50, 50],
  iconAnchor: [5, 5],
})
export const LiveMap: React.FC<Props> = ({className, markers, airportsData, flightInfo}) => {
  const [flight, setFlight] = useState<any>(null)
  const [activePositionReports, setActivePositionReports] = useState<any>([])

  const {
    data: positionReports,
    isLoading: positionReportsLoading,
    error: positionReportsError,
    refetch: positionReportsRefetch,
  } = useQuery(
    ['positionReports', flight],
    () => getPositionReportsByFlightId(flight?.position_report.flightId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      // onSuccess: (data) => {
      //   setActivePositionReports(data)
      // },
    }
  )

  const reports = useMemo(() => {
    if (positionReports?.length) {
      const posReps: any = []
      positionReports.sort((a: any, b: any) => (a.date_time < b.date_time ? 1 : -1))

      positionReports.forEach((rep: any, i: number) => {
        posReps.push([rep.latitude, rep.longitude])
      })
      return posReps
    }
  }, [positionReports])

  const [showSidebar, setShowSidebar] = useState(false)

  const mapRef = useRef<any>(null)

  //const center = [10, 0]

  //   useEffect(() => {
  //     if (mapRef.current) {
  //       mapRef.current.setView(center, 5)
  //     }
  //   }, [center])

  useEffect(() => {}, [positionReports])

  return (
    <div
      className={clsx('card bgi-no-repeat bgi-size-cover px-0', className)}
      style={{
        height: '60vh',
      }}
    >
      <MapContainer
        className='uk-section'
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 'inherit',
          background: '#202224',
        }}
        center={[30, -90]}
        zoom={5}
        ref={mapRef}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={MAPBOX_URI}
        />
        {showSidebar && airportsData && flight ? (
          airportsData
            .filter((a: any) =>
              [flight.schedule.dep_icao, flight.schedule.arr_icao].includes(a.icao)
            )
            .map((airport: any) => (
              <Marker
                key={'airport-' + airport.icao}
                icon={svgIcon}
                position={[airport.latitude, airport.longitude]}
              >
                <Popup>
                  <p>
                    <strong>
                      {airport.icao === flight?.schedule.dep_icao ? 'Departure' : 'Arrival'} ICAO:
                    </strong>{' '}
                    {airport.icao}
                    <br></br>
                    <strong>Name:</strong> {airport.name}
                  </p>
                </Popup>
              </Marker>
            ))
        ) : (
          <></>
        )}
        {/* {positionReports?.length > 0 ? (
          <Polyline key={1} positions={reports} color={'#ededed'} weight={2} opacity={0.6} />
        ) : (
          <></>
        )} */}
        {!!markers.length &&
          markers.map((m: any) => (
            <LeafletTrackingMarker
              key={m.id}
              icon={icon}
              position={[m.position_report.latitude, m.position_report.longitude]}
              previousPosition={m.prevCoords}
              rotationAngle={m.position_report.heading}
              rotationOrigin={'center center'}
              duration={500}
              eventHandlers={{
                click: async () => {
                  // const positionReports = await getPositionReportsByFlightId(
                  //   m.position_report.flightId
                  // )
                  if (!m.position_report.flightId) return
                  setFlight({
                    ...flightInfo[m.position_report.flightId],
                    position_report: m.position_report,
                  })
                  setShowSidebar(true)
                  if (!positionReportsLoading) positionReportsRefetch()
                },
              }}
            />
          ))}
      </MapContainer>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          zIndex: 2,
          //background: 'rgba(0,0,0,.45)',
          pointerEvents: 'none',
          height: '100%',
          borderRadius: 'inherit',
        }}
      >
        <div className='row'>
          {showSidebar && flightInfo && (
            <div className='col-xl-4' style={{pointerEvents: 'all'}}>
              <div
                className='card mx-16 my-20'
                style={{width: 350, maxWidth: 'calc(100vw - 30px)'}}
              >
                <div
                  style={{
                    width: '100%',
                    height: 200,
                    backgroundPosition: 'center, center, center',
                    backgroundImage: `url("${toAbsoluteUrl(
                      `/media/types/A20N.jpg` //`/media/types/${flight.aircraft_type}.jpg`
                    )}")`,
                    backgroundSize: 'cover',
                    borderRadius: 'inherit',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 200,
                      backgroundPosition: 'center, center, center',
                      background: `linear-gradient(to top, rgba(30, 30, 35, 1), rgba(30, 30, 35, .5))`,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </div>
                <div className='card-body pt-0' style={{overflowY: 'auto', maxHeight: '35vh'}}>
                  <div className='row mt-4'>
                    <div className='col-6'>
                      <h2>
                        {flight?.schedule.callsign}
                        {flight?.schedule.flight_number}
                      </h2>
                    </div>
                    <div className='col-6'>
                      <h5 className='mt-0 pt-0'>User:</h5>
                      <p>{flight?.position_report.username}</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <h5>Cruise Altitude:</h5>
                      <p>{flight?.schedule.altitude} Feet</p>
                    </div>
                    <div className='col-6'>
                      <h5>Notes:</h5>
                      <p>{flight?.schedule.notes}</p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-6'>
                      <h5>Current Altitude:</h5>
                      <p>{flight?.position_report.altitude} Feet</p>
                    </div>
                    <div className='col-6'>
                      <h5 className='mt-0 pt-0'>Aircraft Type:</h5>
                      <p>{flight?.schedule.aircraft_type}</p>
                    </div>
                  </div>

                  <br></br>

                  <div>
                    <div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-4 px-4 me-6 mb-3'>
                        <p className='uk-margin-remove-bottom uk-padding-remove-bottom'>
                          {/* {airports?.[0].icao} - {airports?.[0].name} */}
                        </p>
                        <p className='uk-text-italic uk-padding-remove-top uk-margin-remove-top'>
                          {flight?.schedule.dep_icao} -{' '}
                          {
                            airportsData?.find((a: any) => a.icao === flight?.schedule.dep_icao)
                              .name
                          }
                          <br></br>
                          Departure Time:<br></br>
                          {new Date(flight?.dep_time).toLocaleTimeString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div>
                    <div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-4 px-4 me-6 mb-3'>
                        <p className='uk-margin-remove-bottom uk-padding-remove-bottom'>
                          {/* {airports?.[1].icao} - {airports?.[1].name} */}
                        </p>
                        <p className='uk-text-italic uk-padding-remove-top uk-margin-remove-top'>
                          {flight?.schedule.arr_icao} -{' '}
                          {
                            airportsData?.find((a: any) => a.icao === flight?.schedule.arr_icao)
                              .name
                          }
                          <br></br>
                          Estimated Arrival Time:<br></br>
                          {new Date(
                            new Date(flight?.dep_time).getTime() +
                              flight?.schedule.est_flight_time * 3600000
                          ).toLocaleTimeString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* {flight?.log ? (
                      <>
                        <br></br>
                        <div>
                          <div>
                            <h5>Log:</h5>
                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-4 px-4 me-6 mb-3'>
                              <div style={{overflowY: 'auto'}}>
                                {JSON.parse(flight.log).map((event: any) => {
                                  const item = [
                                    [
                                      new Date(event.time).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                      }),
                                    ],
                                    [event.event],
                                  ]
                                  return (
                                    <p
                                      key={'log-item-' + new Date(event.time).getTime()}
                                      className='uk-margin-small uk-text-small'
                                    >
                                      <span className='uk-text-italic'>{item[0]}: </span>
                                      {item[1]}
                                    </p>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  )
}
