export const CARRIER_ID = '93540f78-d020-4c35-a53d-f85aa6151de9'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'DAL'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/edwinbrowwn/cljn0sxa500kw01p1ah426jfr/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
      'https://api.rotate.group/'
    : 'https://api.rotate.group/'

export const styleMapLine = '#0366b4'
export const styleDarkBg = '#181a1b' //#181a1b // _variable.scss $page-bg-dark:
export const styleDarkGbLighter = '#202224'

export const typeGroupings = [{name: 'Airbus 319/320/321/21N', id: 'A320,A321,A319,A21N'}, {name: 'Boeing 737 800/900', id: 'B738,B739'}]
export const discordLink = 'https://discord.gg/KU2utP4M2B'